<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
    >
      <template #cell(photo)="data">
        <div
          v-if="data.item.photo"
          style="width: 100px; height: 100px;"
        >
          <img
            :src="data.item.photo"
            style="height: 100%; max-width: 100%"
          >
        </div>
        <div v-else>
          <img
            src="../../assets/images/avatars/avatr-icon.png"
            width="80"
            height="80"
          >
        </div>
      </template>
      <template #cell(QRCode)="data">
        <QrCode
          v-if="data.item.role !== 'delegator'"
          :item-id="data.item.token"
          :item-name="data.item.name"
        />
      </template>
      <template #cell(active)="data">
        <b-badge
          class="cursor-pointer"
          :variant="data.item.active === 1 ? 'success' : 'danger'"
          style="cursor: auto;"
        >
          {{ resolveActiveUser(data.item.active) }}
        </b-badge>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import QrCode from '@/components/general-table/QrCode.vue'

export default {
  components: {
    GeneralTable,
    QrCode,

  },
  data() {
    return {
      APIURL: 'register/site',
      addType: 'Add User Site',
      addComponentName: 'add-user-site',
      editComponent: 'edit-user-site',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'photo', label: 'photo' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'role', label: 'Role' },
        { key: 'active', label: 'Active' },
        { key: 'job_description', label: 'Job Description' },
        { key: 'phone', label: 'Phone' },
        { key: 'QRCode', label: 'QRCode' },

        { key: 'actions' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = 'register/site?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {
  },
  setup() {
    const resolveActiveUser = role => {
      if (role === 1) return 'Active'

      return 'Not Active'
    }
    return {
      resolveActiveUser,
    }
  },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

<style></style>
